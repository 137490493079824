<template>
  <b-form>
    <b-row>
      <b-col>
        <h5>Body Kendaraan</h5>
        <b-table 
          striped
          small
          responsive
          :fields="fields"
          :items="optionBody"
        >
            
          <template #cell(is_exist)="data">

            <b-form-checkbox 
              v-model="data.item.is_exist"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
          <template #cell(is_work)="data">

            <b-form-checkbox 
              v-model="data.item.is_work"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
          <template #cell(is_good)="data">

            <b-form-checkbox 
              v-model="data.item.is_good"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
        </b-table>

        <h5>Kelengkapan Kendaraan</h5>
        <b-table 
          striped
          small
          responsive
          :fields="fields"
          :items="optionProperty"
        >
            
          <template #cell(is_exist)="data">

            <b-form-checkbox 
              v-model="data.item.is_exist"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
          <template #cell(is_work)="data">

            <b-form-checkbox 
              v-model="data.item.is_work"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
          <template #cell(is_good)="data">

            <b-form-checkbox 
              v-model="data.item.is_good"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
        </b-table>
        <b-form-group
          label-cols-md="4"
          label=""
        >
          <b-button
            class="mr-1"
            variant="primary"
            type="button"
            @click.prevent="validationForm"
          >
            <span v-if="loading">
              <b-spinner small />
              Loading...
            </span>
            <span v-else>Simpan</span>
          </b-button>
          <b-button
            class="mr-1"
            variant="warning"
            type="button"
            @click.prevent="batal"
          >
            <span>Batal</span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {BTable, BCard, BCol, BRow, BButton, BBadge, BForm, BFormGroup, BFormCheckbox, BSpinner} from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BCard,
    BCol, 
    BRow, 
    BForm, 
    BButton,
    BFormGroup,
    FormInput,
    vSelect,
    BFormCheckbox,
    BSpinner,
    flatPickr,
  },
   data() {
    return {   
      loading :false,
      fields:[{
        key:'name',
        label:'Keterangan'
      },{
        key:'is_exist',
        label:'Ada'
      },{
        key:'is_good',
        label:'Baik'
      },{
        key:'is_work',
        label:'Berfungsi'
      }],
      listBody: [],
      listProperty: [],

      optionBody :[],
      optionProperty: [],
      optionFuel :[],
      idForm:null,
      fieldForm:{
        vehicle:null,
        fuel:null,
        vehicle_id:null,
        number:null,
        date: new Date,
        price:null,
        km:null,
        fuel_id:null,
        location: "-",
        longitude:0,
        latitude:0
      },
    }
  },
  mounted(){
    this.getBody()
    this.getProperty()
    if(this.$route.params.id != null){
        this.$http.get('/refueling/'+this.$route.params.id).then(res=>{
          this.idForm = res.data.id
          this.fieldForm={
                vehicle:res.data.vehicle,
                fuel:res.data.fuel,
                vehicle_id:res.data.vehicle.id,
                number:res.data.number,
                date:res.data.date,
                liter:res.data.liter,
                odometer:res.data.odometer,
                fuel_id:res.data.fuel.id,
                price:res.data.price,
              }
        })
    }
  },
  methods:{
    getBody(){
      this.$http.get('vehicle-body').then(res=>{
        this.optionBody = res.data.data.map(value=> {
            return {
              ...value,
              is_exist: false,
              is_good: false,
              is_work: false,
            }
        })
      })
    },
    getProperty(){
      this.$http.get('vehicle-property').then(res=>{
        this.optionProperty = res.data.data.map(value=> {
            return {
              ...value,
              is_exist: false,
              is_good: false,
              is_work: false,
            }
        })
      })
    },
    getfuel(){
      this.$http.get('fuel').then(res=>{
        this.optionFuel = res.data.data
      })
    },
    validationForm() {
      this.$emit('submit',{
        properties: this.optionProperty,
        bodies: this.optionBody
      })
          // this.loading = true
          // if(this.$route.params.id == null)
          //   this.$http.post('/refueling', this.fieldForm)
          //   .then(res=>{
          //     this.idForm = res.data.data.id
          //     this.successSubmit()
          //   })
          //   .catch(err=>{
          //     this.errorSubmit(err)
          //   })
          //   .finally(() => {this.loading = false})
          // else
          //   this.$http.put('/refueling/'+this.$route.params.id, this.fieldForm)
          //   .then(()=>{
          //     this.successSubmit()
          //   })
          //   .catch(err=>{
          //     this.errorSubmit(err)
          //   })
          //   .finally(() => {this.loading = false})
    },
    changeSelectVehicle(data){
      if(data != null){
        this.fieldForm.vehicle_id = data.id
        this.fieldForm.vehicle.id = data.id
        this.fieldForm.vehicle.name = data.name
      }
    },
    changeSelectfuel(data){
      if(data != null){
        this.fieldForm.fuel_id = data.id
        this.fieldForm.fuel.id = data.id
        this.fieldForm.fuel.name = data.name
      }
    },
    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if(this.ismodal){
      this.$emit("onsubmite", true)
      }else{
        this.$router.push({ name:'refueling' })
      }
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    batal(){
      this.$emit('cancel')
      // this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>