<template lang="">
  <div>
    <!-- Start Filter -->
    <!-- b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Type"
          >
            <b-form-select
              v-model="active"
              :options="[
                { value: null, text: 'All' },
                { value: true, text: 'Active' },
                { value: false, text: 'Non-Active' },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            type="button"
            @click.prevent="show"
          >Show</b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
        </b-col>
      </b-row>
    </b-card -->
    <!-- End Of Filter -->
    <b-row>
      <b-col cols="6">
        <b-card>
          <basetable
            ref="basetable"
            :dataurl="dataurl"
            :fields="fields"
            :baseroute="baseroute"
            :title="title"
            :filter="filter"
            :is-edit="false"
            :is-view="false"
            :is-delete="false"
            :is-add="false"
          >
            <!-- template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            Filter
          </b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button>
        </template -->
            <template #cell(vehicle.number)="data">
              <a
                href="#"
                @click="viewForm(data)"
              >{{ data.item.vehicle.number }}</a>
            </template>
            <template #cell(start_at)="data">
              {{ data.item.start_at | moment_full }}
            </template>
          </basetable>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card>
          <h3>Checklist Baru</h3>
          <hr>
          <b-form v-if="isPrepared == false">
            <b-row>
              <b-col>

                <b-form-group
                  label-cols-md="4"
                  label="Kendaraan"
                >
                  <v-select
                    v-model="fieldForm.vehicle"
                    label="number"
                    placeholder="Pilih Kendaraan"
                    :options="optionVehicle"
                    @input="changeSelectVehicle"
                  />
                </b-form-group>

                <b-form-group
                  label-cols-md="4"
                  label="Tanggal"
                >
                  <flat-pickr
                    v-model="fieldForm.start_at"
                    class="form-control"
                    placeholder="Tanggal"
                    :config="config.start_at"
                  />
                </b-form-group>


                <b-form-group
                  label-cols-md="4"
                  label=""
                >
                  <b-button
                    class="mr-1"
                    variant="primary"
                    type="button"
                    @click.prevent="next"
                  >
                    <span>Lanjut</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          <checklist-form
            v-if="isPrepared == true"
            @cancel="isPrepared = false"
            @submit="submit"
          />
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BForm, BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ChecklistForm from './Form'

 
export default {
  components: {
    basetable,
    vSelect,
    flatPickr,
    moment,
    BCard,
    BForm,
    BButton,
    BFormGroup,
    ChecklistForm,

    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      isPrepared: false,
      dataurl:"/check",
      baseroute:"checklist",
      title:"Histori",
      fields: [
        { key: 'vehicle.number', label: 'Nopol', sortable: true, tdClass:"text-nowrap" },
        { key: 'start_at', label: 'Tanggal', sortable: true, tdClass:"text-nowrap"},
        { key: 'author.name', label: 'Petugas', sortable: true, tdClass:"text-nowrap"}
      ],
      showFilter : false,
      filter :[],
      active:null,
      config: {
        start_at : {
          enableTime : true
        }
      },

      optionVehicle :[],
      fieldForm:{
        vehicle:null,
        start_at: new Date
      }
    }
  },
  mounted(){
    this.getVehicle()
  },
  methods:{
    getVehicle(){
      this.$http.get('vehicle?length=').then(res=>{
        this.optionVehicle = res.data.data
      })
    },
    next(){

      if (this.fieldForm.vehicle == null){
        this.$bvToast.toast('Silahkan pilih kendaraan!', {
          title: 'Warning',
          solid: true,
          variant:'warning'
        })
        return
      }
      
     this.isPrepared = true
    },
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[region_id]']=this.region.id
      this.$refs.basetable.fetchData()
      
    },
    reset(){
      this.selectedCheckedBox = []
      this.active = null
      this.show()
    },
    changeSelectVehicle(data){
      if(data != null){
        this.fieldForm.vehicle_id = data.id
        this.fieldForm.vehicle.id = data.id
        this.fieldForm.vehicle.number = data.number
      }
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id} })
    },
    submit(data){
      this.$http.post('/check', {
        vehicle_id: this.fieldForm.vehicle.id,
        start_at: this.fieldForm.start_at,
        is_worthy: true,
        ...data
      })
      .then(res=>{
        this.isPrepared = false
        this.fieldForm = {
          vehicle: null,
          start_at: new Date
        }
        // this.successSubmit()
        this.show()
      })
      .catch(err=>{
        this.errorSubmit(err)
      })
    },

    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if(this.ismodal){
      this.$emit("onsubmite", true)
      }else{
        this.$router.push({ name:'refueling' })
      }
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>
<style lang="">

</style>
